<template>
  <NuxtLink
    class="group block break-inside-avoid-page rounded-lg border border-grey-400 no-underline transition hover:shadow-lg"
    :class="{
      'border-primary': isActive,
    }"
    :to="localePath({name: 'flowers-slug', params: {slug: flowerDay.flower.slug}, query: {date: flowerDay.date}})"
    @click.native="active = true"
  >
    <div
      class="flex items-center gap-1"
      :class="size === 'small' ? 'p-4' : 'p-6'"
    >
      <UiNumbers
        :numbers="flowerDate.toFormat('dd')"
        :active="isActive"
        :size="size"
      />
      <div class="font-lato text-sm font-medium capitalize text-grey">
        {{ flowerDate.toLocaleString({month: 'long'}) }}
      </div>
    </div>
    <div class="rounded-lg bg-secondary-600">
      <FlowerCustomImage
        :flower="flowerDay.flower"
        :image="flowerDay.image_primary_override || flowerDay.flower.image_primary"
        :transition="transition"
      />
    </div>
    <div
      class="flex items-center justify-between py-4"
      :class="size === 'small' ? 'px-4' : 'px-6'"
    >
      <h3
        class="font-lato font-medium transition-colors group-hover:text-primary group-hover:underline"
        :class="size === 'small' ? 'text-base' : 'text-xl'"
      >
        {{ flowerDay.flower.title_latin }}
      </h3>
      <UiButton
        v-if="size === 'normal'"
        icon="fa6-regular:heart"
        icon-size="medium"
        shape="pill"
        @click.prevent="addToFavorites = true"
      />
    </div>

    <CollectionModalAddFromFlower
      v-model:open="addToFavorites"
      :flower="flowerDay.flower"
    />
  </NuxtLink>
</template>

<script lang="ts" setup>
import {DateTime} from 'luxon';
import type {FlowerDay} from '~/graphql/graphql';

const props = withDefaults(defineProps<{
  flowerDay: FlowerDay,
  size?: 'small' | 'normal',
  transition?: boolean,
}>(), {
  size: 'normal',
  transition: true,
});

const active = ref(false);
const addToFavorites = ref(false);

const flowerDate = computed(() => DateTime.fromSQL(props.flowerDay.date));
const isActive = computed(() => flowerDate.value.hasSame(DateTime.now(), 'day'));
</script>
